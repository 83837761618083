<template>
  <b-card>
    <e-charts ref="line" :options="option" theme="theme-color" autoresize />
  </b-card>
</template>
  
  <script>
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";

export default {
  components: {
    ECharts,
  },
  props: {
    title: {
      type: String,
      default: "Insert title as props",
    },
    data: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      option: {
        title: {
          show: true,
          text: "faaa",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.data.map((element) => element.name),
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: function (value, index) {
                const colorsChart = [
                  "#1f77b4",
                  "#ff7f0e",
                  "#2ca02c",
                  "#d62728",
                  "#9467bd",
                  "#8c564b",
                  "#e377c2",
                  "#7f7f7f",
                  "#bcbd22",
                  "#17becf",
                  "#aec7e8",
                  "#ffbb78",
                  "#98df8a",
                  "#ff9896",
                  "#c5b0d5",
                  "#c49c94",
                  "#f7b6d2",
                  "#c7c7c7",
                  "#dbdb8d",
                  "#9edae5",
                ];
                return colorsChart[index];
              },
            },

            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#616161",
                type: "dashed",
                width: 1.3,
                interval: 10,
              },
            },
          },
        ],
        series: [
          {
            name: "value",
            type: "bar",
            barWidth: "30%",
            emphasis: {
              focus: "series",
            },
            data: this.data.map((element) => element.value),
          },
        ],
      },
    };
  },
};
</script>
  
  <style>
.echarts {
  width: 90% !important;
  margin: auto;
}
</style>
  