<template>
  <b-card>
    <e-charts ref="line" :options="option" autoresize />
  </b-card>
</template>

<script>
import ECharts from "vue-echarts";

export default {
  components: {
    ECharts,
  },
  props: {
    title: {
      type: String,
      default: "Insert title as props",
    },
    data: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      option: {
        title: {
          text: this.title,
          left: "center",
          textStyle: {
            color: "#c23531",
          },
        },
        legend: {
          bottom: 10,
          left: "center",
          data: this.data.map((element) => element.name),
          padding: [100, 0, 0, 0],
          textStyle: {
            color: "inherit",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },

        series: [
          {
            name: "Value",
            type: "pie",
            radius: "60%",
            center: ["50%", "40%"],
            data: this.data.map((element) => ({
              value: element.value.value,
              name: element.name,
            })),
            // roseType: "radius",
            color: this.colors,
            animationType: "scale",
            animationEasing: "elasticOut",

            animationDelay: function (idx) {
              return Math.random() * 200;
            },
            label: {
              show: true,
              formatter: "{b} : {c}", // "{b}" representa el nombre y "{c}" representa el valor
            },
            emphasis: {
              label: {
                show: true,
              },
            },
          },
        ],
      },
    };
  },
};
</script>

<style>
.echarts {
  width: 90% !important;
  margin: auto;
}
</style>
