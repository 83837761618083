<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button variant="primary" @click="goBack()"
          ><feather-icon icon="ChevronLeftIcon"></feather-icon> RETURN</b-button
        >
      </template>
    </header-slot>
    <b-row>
      <b-col xl="4" lg="5" md="12" sm="12" xs="12" class="card">
        <div class="mx-auto" v-if="!isBusy">
          <div class="d-flex justify-content-center">
            <b-avatar
              class="image-flyer mt-3"
              square
              size="300px"
              :src="flyer.route_thumb"
              icon="file-earmark-image-fill"
            />
          </div>
          <div class="mt-1 mx-auto ml-1">
            <p>
              Flyer Name: <b>{{ flyer.flyer_name }}</b>
            </p>
            <p>
              Campaign: <b>{{ flyer.campaign }}</b>
            </p>
            <p>
              Created By:
              <b>{{ String(flyer.full_name).toUpperCase() }}</b>
            </p>
            <p>
              Publication Date:
              <b>{{ flyer.publication_date | myGlobal }}</b>
            </p>
            <p>
              Total Replies:
              <b>{{ flyer.nreplies }}</b>
            </p>
            <p>
              Total Leads:
              <b>{{ flyer.nleads }}</b>
            </p>
          </div>
        </div>
      </b-col>
      <b-col xl="8" lg="7" md="12" sm="12" xs="12">
        <CardChart :chartType="'pie'" :filters="paramsFilter" />
      </b-col>
      <b-col xl="12" lg="12" md="12" sm="12" xs="12">
        <filter-slot
          :filter-principal="filterPrincipal"
          :filter="filter"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="refresh"
        >
          <template #table>
            <b-table
              slot="table"
              ref="refsTable"
              small
              no-provider-filtering
              :items="myProvider"
              :fields="fields"
              primary-key="id"
              table-class="text-nowrap"
              responsive="sm"
              show-empty
              sticky-header="70vh"
              :busy.sync="isBusy"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
              class="overflow-y"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #cell(full_name)="data">
                <div class="mt-1">
                  {{ data.item.full_name ? data.item.full_name : "-" }}
                </div>
              </template>
              <template #cell(nickname)="data">
                <div>
                  <router-link
                    class="d-block text-primary"
                    :class="textLink"
                    :to="`/socialnetwork/leads/new/dashboard/${data.item.lead_id}`"
                    target="_blank"
                  >
                    {{
                      data.item.nickname
                        ? data.item.nickname
                        : "-" | myFontCapitalize
                    }}
                  </router-link>
                </div>
                <div style="white-space: pre-wrap">
                  <span class="d-block">{{
                    data.item.full_name
                      ? data.item.full_name
                      : "-" | myFontCapitalize
                  }}</span>
                  <br />
                  <small>{{ data.item.lead_name }}</small>
                </div>
              </template>
              <template #cell(nreplies)="data">
                <div class="mt-1">
                  <b-badge variant="primary">{{ data.item.nreplies }}</b-badge>
                </div>
              </template>
              <template #cell(created_at)="data">
                <div class="mt-1">
                  {{ data.item.created_at | myGlobal }}
                </div>
              </template>
              <template #cell(owner)="data">
                <div class="mt-1">
                  {{ data.item.owner }}
                </div>
              </template>
              <template #cell(source)="data">
                <div>
                  <div class="d-flex flex-column align-items-center">
                    <b-img
                      fluid
                      :src="data.item.icon_url"
                      style="width: 25px"
                    />
                    <span>
                      {{ String(data.item.source).toUpperCase() }}
                    </span>
                  </div>
                </div>
              </template>
              <template #cell(sale)="data">
                <div class="mt-1">
                  <b-badge variant="success" v-if="data.item.sale == 4"
                    >With Sale</b-badge
                  >
                  <b-badge variant="warning" v-else>Without Sale</b-badge>
                </div>
              </template>
              <template #cell(mobile)="data">
                <div class="mt-1">
                  {{ data.item.mobile ? data.item.mobile : "-" }}
                </div>
              </template>
              <template #cell(potencial)="data">
                <div style="margin-top: 4px">
                    <b-badge :variant="data.item.potencial == 'NO' ? 'warning': 'primary'">{{data.item.potencial == 'NO' ? 'NO POTENTIAL': 'POTENTIAL'}}</b-badge>
                    <div style="margin-top: 3px">
                      <b-badge :variant="data.item.potencial == 'NO' ? 'secondary': 'success'">{{ data.item.description }}</b-badge>
                    </div>
                </div>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dataFields from "@/views/social-network/views/statistics/method-contact/data/fieldsDetail.data.js";
import dataFilters from "@/views/social-network/views/statistics/method-contact/data/filtersDetail.data.js";
import CardChart from "@/views/social-network/views/statistics/method-contact/components/CardChart.vue";
import StatisTicsService from "@/views/social-network/views/statistics/services/statistics.service";
import SettingsService from "@/views/social-network/views/settings/settings.service.js";

export default {
  components: {
    CardChart,
  },
  data() {
    return {
      filter: dataFilters,
      fields: dataFields,
      flyerId: this.$route.params.flyer,
      program: this.$route.params.program,
      flyer: {
        flyer_name: "",
        route_thumb: "",
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by nickname, fullname and number",
        model: "",
      },
      sortBy: "created_at",
      sortDesc: true,
      startPage: 0,
      endPage: null,
      nextPage: null,
      toPage: 0,
      isBusy: false,
      paramsFilter: {},
    };
  },

  methods: {
    setOptionsOnFilters(options) {
      if (this.filter[0].options.length == 0) {
        this.filter[0].options = options
          .filter(
            (obj, index, self) =>
              index ===
              self.findIndex(
                (t) => t.owner_id === obj.owner_id && t.owner === obj.owner
              )
          )
          .map((element) => ({
            value: element.owner_id,
            label: element.owner,
          }));
      }
    },
    refresh() {
      this.$refs.refsTable.refresh();
    },
    goBack() {
      this.$router.push({
        name: "list-source-methods-social-network",
        params: { program: this.program },
      });
    },

    async getSourceMethods() {
      const { data } = await SettingsService.getSourceMethodsByProgram();
      data.unshift({ value: "all", label: "ALL" });
      this.filter[2].options = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
    },

    async getFlyer() {
      const params = {
        flyer: this.flyerId,
        program: this.program,
        source: this.filter[2].model
          ? this.filter[2].model
          : localStorage.getItem("currentSource"),
        method: localStorage.getItem("currentMethod"),
        search_txt: this.filterPrincipal.model,
        has_value: this.filter[3].model,
        owner: this.filter[0].model,
        potential: this.filter[1].model,
      };
      const { data } = await StatisTicsService.getFlyerByName(params);
      if (data.length > 0) {
        return data[0];
      } else {
        return { ...this.flyer, nreplies: 0, nleads: 0 };
      }
    },

    async myProvider(ctx) {
      let sort_By = ctx.sortBy;
      let sortDirection = ctx.sortDesc;
      try {
        this.isBusy = true;
        this.addPreloader();
        this.paramsFilter = {
          search_text: this.filterPrincipal.model,
          page: this.paginate.currentPage,
          per_page: this.paginate.perPage,
          flyer: this.flyerId,
          order: sortDirection ? "desc" : "asc",
          orderby: sort_By,
          owner: this.filter[0].model,
          potential: this.filter[1].model,
          program: this.program,
          source: this.filter[2].model
            ? this.filter[2].model
            : localStorage.getItem("currentSource"),
          method_contact: localStorage.getItem("currentMethod"),
          has_value: this.filter[3].model,
        };

        const data = await StatisTicsService.getLeadsByflyerId(
          this.paramsFilter
        );
        this.flyer = await this.getFlyer();
        this.startPage = data.from ? data.from : 0;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        this.setOptionsOnFilters(data.data);
        return data.data || [];
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
        this.isBusy = false;
      }
    },
  },
  async created() {
    await this.getSourceMethods();
  },
};
</script>
<style scoped>
.image-flyer {
  object-fit: cover;
  width: 80%;
  height: 250px;
  border-radius: 12px !important;
  margin: 0 auto;
}
</style>
