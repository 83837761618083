import { amgApi } from '@/service/axios'

class StatisticsService {
    //TabBYProgram
    async createListRecoveryByProgram(params) {
        const { data } = await amgApi.post("/social-network/recovery-list/create-recovery-list", params);
        return data;
    }
    //userId,programId,cant

    async getFlyersByProgramAndMethod(params) {
        const { data } = await amgApi.post("/social-network/bank-of-flyers/get-flyers-by-program-method", params);
        return data;
    }
    
    async getLeadsByflyerId(params) {
        const { data } = await amgApi.post("/social-network/bank-of-flyers/get-leads-by-flyer-name", params);
        return data;
    }

    async getReportLeadsByFlyer(params) {
        const { data } = await amgApi.post("/social-network/bank-of-flyers/report-leads-by-flyer",params);
        return data;
    }

    async getFlyerByName(params) {
        const data  = await amgApi.post("/social-network/bank-of-flyers/get-flyer-with-replies-leads",params);
        return data;
    }
}
export default new StatisticsService()