export default [

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Owner *',
    model: null,
    options: [],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Status Potential',
    model: null,
    options: [
        { value: 1, label: 'POTENTIAL' },
        { value: 2, label: 'NO POTENTIAL' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
},
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Source *',
    model: null,
    options: [],
    reduce: 'value',
    selectText: 'label',
    visible: true,
    cols: 12,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Lead',
    model: null,
    options: [
        { value: 1, label: 'WITH NUMBER' },
        { value: 2, label: 'WITHOUT NUMBER' },
        { value: 3, label: 'WITH SALE' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
},

]
