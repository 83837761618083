<template>
  <div class="card">
    <b-row class="d-flex mt-2 mx-2">
      <b-col cols="6">
        <b-form-group label="Type Report" label-class="font-weight-bolder">
          <v-select
            v-model="reportType"
            label="text"
            :options="optionsReport"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6" class="mt-2 d-flex justify-content-end">
        <p class="d-inline mr-1">Bar</p>
        <b-form-checkbox
          switch
          class="d-inline"
          v-model="chartType"
        ></b-form-checkbox>
        <p class="pl-1">Pie</p>
      </b-col>
    </b-row>

    <div v-if="!loading">
      <CustomPieChart
        :title="reportType.text"
        :data="dataChart"
        :colors="colorsChart"
        v-if="chartType"
      />
      <CustomPBarChart :title="reportType.text"  :colors="colorsChart" :data="dataChart" v-else />
    </div>
    <div v-else class="text-center text-primary my-2">
      <b-spinner class="align-middle mr-1" />
      <strong>Loading ...</strong>
    </div>
  </div>
</template>

<script>
import CustomPieChart from "@/views/social-network/views/statistics/method-contact/components/charts/CustomPieChart.vue";
import CustomPBarChart from "@/views/social-network/views/statistics/method-contact/components/charts/CustomBarChart.vue";

import StatisTicsService from "@/views/social-network/views/statistics/services/statistics.service";
export default {
  components: {
    CustomPieChart,
    CustomPBarChart,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      chartType: true,
      prevSearch: true,
      loadingData: true,
      program: this.$route.params.program,
      optionsReport: [
        { value: 1, text: "FOR NUMBER" },
        { value: 2, text: "FOR POTENTIAL" },
        { value: 3, text: "FOR SALE" },
        { value: 4, text: "LEADS FOR SOURCE" },
        { value: 5, text: "REPLIES FOR SOURCE" },

        { value: 6, text: "REASON: NO ANSWER" },
        { value: 7, text: "REASON: NO MOBILE" },
        { value: 8, text: "REASON: NO APPOINTMENT TO FILTER" },
        { value: 9, text: "REASON: NO POTENTIAL" },
        { value: 10, text: "REASON: OTHERS" },
        { value: 11, text: "REASON: POTENTIAL" },
      ],
      reportType: { value: 1, text: "FOR NUMBER" },
      dataChart: [],
      colorsChart: [
        "#1f77b4", // Azul
        "#ff7f0e", // Naranja
        "#2ca02c", // Verde
        "#d62728", // Rojo
        "#9467bd", // Morado
        "#8c564b", // Marrón
        "#e377c2", // Rosa
        "#7f7f7f", // Gris
        "#bcbd22", // Verde oliva
        "#17becf", // Turquesa
        "#aec7e8", // Azul claro
        "#ffbb78", // Naranja claro
        "#98df8a", // Verde claro
        "#ff9896", // Rojo claro
        "#c5b0d5", // Morado claro
        "#c49c94", // Marrón claro
        "#f7b6d2", // Rosa claro
        "#c7c7c7", // Gris claro
        "#dbdb8d", // Verde oliva claro
        "#9edae5", // Turquesa claro
      ],
    };
  },

  methods: {
    async getReportLeads() {
      this.prevSearch = false;
      this.loading = true;
      const params = {
        report_type: this.reportType.value,
        flyer: this.$route.params.flyer,
        source: this.filters.source,
        owner: this.filters.owner,
        program: this.program,
        contact: this.filters.method_contact,
      };
      const data = await StatisTicsService.getReportLeadsByFlyer(params);

      // if(this.reportType.value == 6){
      //   this.createColorForChart(data.length);
      // }

      this.dataChart = data.map((element, index) => ({
        value: {
          value: element.value,
          itemStyle: {
            color: this.colorsChart[index],
          },
        },
        name: element.categories,
      }));
      this.loading = false;
    },

    // createColorForChart(quantity){
    //   const colorsArray = [];
    //   const paletteLength = this.colorsChart.length;
    //   for (let i = 0; i < quantity; i++) {
    //     colorsArray.push(this.colorsChart[i % paletteLength]);
    //   }
    //   this.colorsChart = colorsArray;
    // }
  },

  watch: {
    reportType(newValue) {
      if (newValue != null) {
        this.getReportLeads();
      }
    },

    filters: {
      inmediate: true,
      async handler() {
        await this.getReportLeads();
      },
    },
  },
};
</script>