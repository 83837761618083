export default [

    {
      key: "nickname",
      label: "Nickname",
      class: "text-left",
      visible: true,
    },
    {
      key: "created_at",
      label: "Created Date",
      class: "text-center",
      sortable: true,
      visible: true,
    },
    {
      key: "owner",
      label: "Owner",
      class: "text-center",
      visible: true,
    },

    {
      key: "source",
      label: "Source",
      class: "text-center",
      visible: true,
    },
    {
      key: "nreplies",
      label: "Replies",
      class: "text-center",
      sortable: true,
      visible: true
    },
    {
      key:"sale",
      label:"Sale",
      class: "text-center",
      visible: true
    },
    {
      key:"mobile",
      label:"Number",
      class: "text-center",
      visible: true
    },
    {
      key: "potencial",
      label: "Status Potential",
      class: "text-center",
      visible: true,
    },
  ]